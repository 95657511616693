import React, { useState, useEffect } from 'react';
import spinner from '../images/spinner.gif';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
//import { load } from '@cashfreepayments/cashfree-js';
import currencyData from './currencies.json';
//import eventData from '../components/events.json'; // Import your event data


const API_URL = 'https://manamvanam.com/api';
const EVENT_API_URL = 'https://manamvanam.com/api/events';

function loadScript(src) {
     return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
               resolve(true);
          };
          script.onerror = () => {
               resolve(false);
          };
          document.body.appendChild(script);
     });
}

async function displayRazorpay(razorPayOptions, navigate) {

     const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
     );
     razorPayOptions.PaymentRef.handler = async function (response) {
          const data = {
               orderCreationId: razorPayOptions.OrderId,
               razorpayPaymentId: response.razorpay_payment_id,
               razorpayOrderId: response.razorpay_order_id,
               razorpaySignature: response.razorpay_signature,
          };

          const result = await axios.post(`${API_URL}/RPPaymentVerify`, data).then(response => {
               //if (response.data) {
               //    console.log(response);
               //    navigate(`/PaymentSuccess?myorder=${razorPayOptions.OrderId}`);
               //} else {
               //    navigate('/paymentfailed');
               //}
               if (response.data.IsCaptured) {
                    console.log(response);
                    navigate(`/PaymentSuccessful`, { state: response.data });
               } else {
                    navigate('/paymentfailed');
               }
          }).catch(error => {

          });;

          //alert(result.data.msg);
     };
     razorPayOptions.PaymentRef.modal = {
          ondismiss: function () {
               //navigate(`/PaymentSuccess?myorder=${razorPayOptions.PaymentRef.order_id}`);
               axios.get(`${API_URL}/OrderPayments/${razorPayOptions.OrderId}`)
                    .then(response => {
                         console.log(response);
                         const fetchedData = response.data;
                         if (fetchedData.OrderStatus !== "paid") {
                              navigate('/paymentfailed');
                         } else { navigate(`/PaymentSuccess?myorder=${razorPayOptions.OrderId}`); }
                    })
                    .catch(error => {
                         // Handle errors
                    });
          },
          // Boolean indicating whether pressing escape key 
          // should close the checkout form. (default: true)
          escape: true,
          // Boolean indicating whether clicking translucent blank
          // space outside checkout form should close the form. (default: false)
          backdropclose: false
     };
     if (!res) {
          alert("Razorpay SDK failed to load. Are you online?");
          return;
     }

     const paymentObject = new window.Razorpay(razorPayOptions.PaymentRef);
     paymentObject.open();
}

const Donation = ({
     showPersonalDetails,
     showServiceSelection,
     showRythuBandhamEvents,
     showGirijanaBandhamEvents,
     showGramaBandhamEvents,
     showJanahitaBandhamEvents,
     showParyavaranamBandhamEvents,
     showLogin,
     showAllEvents,
     showServiceWiseEvents,
     displayEvents,
     selectedServiceId
}) => {
     const [selectedRythuEvent, setSelectedRythuEvent] = useState('');
     const [selectedGirijanaEvent, setSelectedGirijanaEvent] = useState('');
     const [selectedGramaEvent, setSelectedGramaEvent] = useState('');
     const [selectedJanahitaEvent, setSelectedJanahitaEvent] = useState('');
     const [selectedParyavaranamEvent, setSelectedParyavaranamEvent] = useState('');
     const [selectedEvent, setSelectedEvent] = useState('');
     const [showLoader, setShowLoader] = useState(false);

     //const [sortedEvents, setSortedEvents] = useState([]);
     //const [allEvents, setAllEvents] = useState([]);
     if (displayEvents === undefined)
          displayEvents = [];
     const sortedEvents = displayEvents
          .filter((event) => new Date(event.EventStartDate) >= new Date())
          .sort((a, b) => new Date(a.EventStartDate) - new Date(b.EventStartDate));

     const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
     const token = localStorage.getItem('token');

     const config = {
          headers: {
               Authorization: `Bearer ${token}`,
          },
     };

     const allEvents = displayEvents
          .filter((event) => new Date(event.EventStartDate) >= new Date())
          .sort((a, b) => new Date(a.EventStartDate) - new Date(b.EventStartDate))
          .map((event) => ({
               value: event.Id,
               label: `${event.EventName} (${event.EventCity})`,
          }));

     const handleEventSelect = (event) => {
          setSelectedEvent(event.target.value);
     };

     const handleRythuEventSelect = (event) => {
          setSelectedRythuEvent(event.target.value);
     };
     const handleGirijanaEventSelect = (event) => {
          setSelectedGirijanaEvent(event.target.value);
     };
     const handleGramaEventSelect = (event) => {
          setSelectedGramaEvent(event.target.value);
     };
     const handleJanahitaEventSelect = (event) => {
          setSelectedJanahitaEvent(event.target.value);
     };
     const handleParyavaranamEventSelect = (event) => {
          setSelectedParyavaranamEvent(event.target.value);
     };



     const navigate = useNavigate();
     const [step, setStep] = useState(1);
     const [acceptedTerms, setAcceptedTerms] = useState(false); // State for accepting terms and conditions
     const [acceptedTermsError, setAcceptedTermsError] = useState('');
     const [selectedType, setSelectedType] = useState('recurring'); // Default selected type
     const [donationAmount, setDonationAmount] = useState('');
     const [activeAmount, setActiveAmount] = useState(null);
     const [donationAmountError, setDonationAmountError] = useState('');

     const [firstName, setFirstName] = useState(userDetails ? userDetails.FirstName : '');
     const [lastName, setLastName] = useState(userDetails ? userDetails.LastName : '');
     const [lastNameError, setLastNameError] = useState('');
     const [phoneNumber, setPhoneNumber] = useState('');
     const [email, setEmail] = useState(userDetails ? userDetails.Email : '');
     const [firstNameError, setFirstNameError] = useState('');
     const [phoneNumberError, setPhoneNumberError] = useState('');
     const [emailError, setEmailError] = useState('');

    var servicesData = JSON.parse(sessionStorage.getItem("manamvanam_services"));
    const serviceOptions = [
        { value: '0', label: 'All' }
    ]
    for (var i = 0; i < servicesData.length; i++) {
        serviceOptions.push({ value: servicesData[i].master_service_id, label: servicesData[i].service_title });
    }
    console.log(serviceOptions);
     //const serviceOptions = [
     //     { value: '0', label: 'All' },
     //     { value: '1', label: 'Rythu Bandham' },
     //     { value: '2', label: 'Girijana Bandham' },
     //     { value: '3', label: 'Grama Bandham' },
     //     { value: '4', label: 'Janahita Bandham' },
     //     { value: '5', label: 'Paryavaranam Bandham' },
     //];


     const [selectedServices, setSelectedServices] = useState([{ value: '0', label: 'All' }]); // State for selected services

     const handleServiceSelect = (selectedOptions) => {
          // Check if "All" is selected
          const allOption = serviceOptions.find((option) => option.value === 'all');
          if (allOption && selectedOptions.includes(allOption)) {
               // Select all options except "All"
               setSelectedServices(
                    serviceOptions.filter((option) => option.value !== 'all')
               );
          } else {

               if (!showServiceSelection)
                    setSelectedServices(serviceOptions.find((option) => option.value === selectedServiceId));
               else
                    setSelectedServices(selectedOptions);
          }
     };


     const [serviceTypeError, setServiceTypeError] = useState(''); // State for type validation error
     const [serviceSelectionError, setServiceSelectionError] = useState('');


     const [activeSuggestedAmount, setActiveSuggestedAmount] = useState(null); // New state for active suggested amount

     const handleSuggestedAmountClick = (amount) => {
          let updatedAmount = amount;
          if (selectedType === 'oneTime') {
               updatedAmount *= 100; // Convert suggested amount to rupees (e.g., 1k to 1000.00)
          }

          setDonationAmount(updatedAmount.toFixed(2));
          setActiveSuggestedAmount(amount);
          setDonationAmountError(''); // Clear the error message
     };

     const handleCustomAmountClick = () => {
          setDonationAmount('');
          setActiveSuggestedAmount(null);
     };

     const [activeAmountButton, setActiveAmountButton] = useState(null);


     // const handleDonationAmountChange = (event) => {
     //      const value = event.target.value;
     //      if (value === '') {
     //           setDonationAmountError('Donation amount is required.');
     //      } else if (/^\d+(\.\d{0,2})?$/.test(value)) {
     //           setDonationAmount(value);
     //           setDonationAmountError(''); 
     //           setActiveAmountButton(null);
     //      }
     // };

     const handleDonationAmountChange = (event) => {
          const value = event.target.value;
          if (event.target.type === 'number' && event.nativeEvent.inputType === 'deleteContentBackward') {
               setDonationAmount('');
               setActiveSuggestedAmount(null);
          } else if (value === '') {
               setDonationAmountError('Donation amount is required.'); // Show error if the field is empty
          } else if (/^\d+(\.\d{0,2})?$/.test(value)) {
               setDonationAmount(value);
               setDonationAmountError(''); // Clear the error message when valid input is entered
               // Clear active class from suggestion buttons when a custom amount is entered
               setActiveAmountButton(null);
          }
     };


     const handleRadioChange = (type) => {
          setSelectedType(type);
          setActiveAmount(null);
          setDonationAmount(''); // Clear the donation amount when the type changes

          // Clear any error messages
          setDonationAmountError('');
          setServiceTypeError('');
          setServiceSelectionError('');
     };



     const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
     const phoneRegex = /^\+?[0-9]{6,15}$/;


     const handleMakePayment = () => {
          let isValid = true;

          if (donationAmount === '') {
               setDonationAmountError('Donation amount is required.');
               isValid = false;
          } else {
               setDonationAmountError('');
          }

          if (selectedServices === null) {
               setServiceTypeError('Please select a service type.');
               isValid = false;
          } else {
               setServiceTypeError('');
          }

          // Validate first name
          const firstNameRegex = /^[A-Za-z]+$/; // Regex to allow only letters
          if (firstName.trim() === '') {
               setFirstNameError('First Name is required');
               isValid = false;
          } else if (!firstNameRegex.test(firstName)) {
               setFirstNameError('First Name should only contain letters');
               isValid = false;
          } else {
               setFirstNameError('');
          }

          // Validate last name (if provided)
          if (lastName.trim() !== '') {
               const lastNameRegex = /^[A-Za-z]+$/; // Regex to allow only letters
               if (!lastNameRegex.test(lastName)) {
                    setLastNameError('Last Name should only contain letters');
                    isValid = false;
               } else {
                    setLastNameError('');
               }
          }

          // Validate phone number
          if (phoneNumber.trim() === '') {
               setPhoneNumberError('Phone Number is required');
               isValid = false;
          } else if (!phoneRegex.test(phoneNumber)) { // Check if the phone number matches the regex pattern
               setPhoneNumberError('Invalid Phone Number');
               isValid = false;
          } else {
               setPhoneNumberError('');
          }

          // Validate email
          if (email.trim() === '') {
               setEmailError('Email is required');
               isValid = false;
          } else if (!emailRegex.test(email)) { // Check if the email matches the regex pattern
               setEmailError('Invalid Email');
               isValid = false;
          } else {
               setEmailError('');
          }

          if (!acceptedTerms) {
               setAcceptedTermsError('Please accept the Terms and Conditions');
               isValid = false;
          } else {
               setAcceptedTermsError('');
          }

         if (isValid) {
             setShowLoader(true);
               var selectedServicesWithEvents;
               if (showServiceSelection) {
                    selectedServicesWithEvents = selectedServices.map((item, index) => ({
                         "ServiceId": item.value, "EventId": null
                    }));
               } else {
                    selectedServicesWithEvents = [
                         { "ServiceId": selectedServiceId, "EventId": selectedEvent }
                    ];
               }
               // Proceed with payment
               // Add your payment logic here
               axios.post(`${API_URL}/Donation`, {
                    "FirstName": firstName,
                    "LastName": lastName,
                    "EmailId": email,
                    "Mobile": phoneNumber,
                    "DonationType": selectedType === "recurring" ? 2 : 1,
                    "DonationAmount": donationAmount,
                    "DonationCurrency": selectedCurrency.value,
                    //"Services": [
                    //    { "ServiceId": 2,"EventId":null}
                    //]
                    "Services": selectedServicesWithEvents
               }).then(response => {
                    console.log(response);
                    displayRazorpay(response.data, navigate);
               })
                    .catch(error => {

                    }).finally(() => {
                        setShowLoader(false); // Hide loader
                    });
          }
     };

     const handleMakePaymentWithOutPersonalDetails = () => {
          let isValid = true;

          if (donationAmount === '') {
               setDonationAmountError('Donation amount is required.');
               isValid = false;
          } else {
               setDonationAmountError('');
          }

          if (selectedServices === null) {
               setServiceTypeError('Please select a service type.');
               isValid = false;
          } else {
               setServiceTypeError('');
          }

          if (!acceptedTerms) {
               setAcceptedTermsError('Please accept the Terms and Conditions');
               isValid = false;
          } else {
               setAcceptedTermsError('');
          }

         if (isValid) {
                setShowLoader(true); // Show loader
               var selectedServicesWithEvents;
               if (showServiceSelection) {
                    selectedServicesWithEvents = selectedServices.map((item, index) => ({
                         "ServiceId": item.value, "EventId": null
                    }));
               } else {
                    selectedServicesWithEvents = [
                         { "ServiceId": selectedServiceId, "EventId": selectedEvent }
                    ];
               }
               // Proceed with payment
               // Add your payment logic here
               axios.post(`${API_URL}/Donation`, {
                    "FirstName": firstName,
                    "LastName": lastName,
                    "EmailId": email,
                    "Mobile": phoneNumber,
                    "DonationType": selectedType === "recurring" ? 2 : 1,
                    "DonationAmount": donationAmount,
                    "DonationCurrency": selectedCurrency.value,
                    //"Services": [
                    //    { "ServiceId": 2,"EventId":null}
                    //]
                    "Services": selectedServicesWithEvents
               }, config).then(response => {
                    console.log(response);
                    displayRazorpay(response.data, navigate);
               })
                    .catch(error => {

                    }).finally(() => {
                        setShowLoader(false); // Hide loader
                    });
          }
     };

     const isValidEmail = (email) => {
          // Use a regular expression for basic email format validation
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
     };


     const [selectedCurrency, setSelectedCurrency] = useState(null);

     useEffect(() => {
          const defaultCurrency = currencyOptions.find(option => option.value === 'INR');
          setSelectedCurrency(defaultCurrency); // Set default currency to INR
     }, []); // Run this effect only once when the component mounts
     
     const currencyOptions = Object.values(currencyData).map((currency) => ({
          symbol: currency.symbol,
          value: currency.value,
          // label: `${currency.name} | ${currency.value}`,
          label: `${currency.value}`,
     }));

     const handleCurrencyChange = (selectedOption) => {
          setSelectedCurrency(selectedOption);
     };

     const customOptionLabel = ({ label, symbol }) => (
          <div>
               {label} | <span dangerouslySetInnerHTML={{ __html: symbol }} />
          </div>
     );

     //useEffect(() => {
     //    axios.get(`${EVENT_API_URL}`)
     //        .then(response => {
     //            const fetchedData = response.data;
     //            console.log(fetchedData);
     //            setEventsData(fetchedData); // Store the fetched data in state

     //            var localSortedEvents = fetchedData
     //                .filter((event) => new Date(event.EventStartDate) >= new Date())
     //                .sort((a, b) => new Date(a.EventStartDate) - new Date(b.EventStartDate));
     //            setSortedEvents(fetchedData);
     //            var localAllEvents = fetchedData
     //                .filter((event) => new Date(event.EventStartDate) >= new Date())
     //                .sort((a, b) => new Date(a.EventStartDate) - new Date(b.EventStartDate))
     //                .map((event) => ({
     //                    value: event.Id,
     //                    label: `${event.EventName} (${event.EventCity})`,
     //                }));
     //            setAllEvents(fetchedData);
     //        })
     //        .catch(error => {
     //            // Handle errors
     //        });



     //}, [selectedServices]);
     return (
         <>
             <div className={`loader-main ${showLoader ? 'show' : 'hide'}`}>
                 <div className="loader-sub">
                     <img src={spinner} alt="Loading..." />
                 </div>
             </div>
               <div className="row">
                    <div className='card common-card don-card mb-10'>

                         <div className=''>
                              <div className='card common-card mb-15 don-card p-15' style={{ padding: '0 1.5vh 0 1.5vh' }}>
                                   <div className="don-silver-bg">
                                        <div className="row">
                                             <div className="col p-0">

                                                  <button
                                                       className={`btn w-100 btn-transparent btn-${selectedType === 'oneTime' ? 'primary' : 'light'} mr-5`}
                                                       onClick={() => handleRadioChange('oneTime')}
                                                  >
                                                       One Time
                                                  </button>
                                             </div>
                                             <div className="col p-0">

                                                  <button
                                                       className={`btn w-100 btn-transparent btn-${selectedType === 'recurring' ? 'primary' : 'light'} mr-5`}
                                                       onClick={() => handleRadioChange('recurring')}
                                                  >
                                                       Recurring(Monthly)
                                                  </button>
                                             </div>
                                        </div>
                                        {serviceTypeError && (
                                             <p className="text-danger mb-0">{serviceTypeError}</p>
                                        )}
                                   </div>
                              </div>
                              <div className='card common-card mb-0'>

                                   <div className="card-body" style={{ padding: '0 1.5vh 0 1.5vh' }}>
                                        <div className="input-group">
                                             {selectedCurrency && (
                                                  <p className="symbol-icon" style={{ margin: '0' }}
                                                       dangerouslySetInnerHTML={{
                                                            __html: selectedCurrency.symbol,
                                                       }}
                                                  />
                                             )}
                                             <input
                                                  type="text"
                                                  id="donationAmount"
                                                  className="form-control"
                                                  value={donationAmount}
                                                  placeHolder="Enter Donation Amount"
                                                  style={{ padding: '1.5vh 1.5vh 1.5vh 4.5vh', marginRight:'15px' }}
                                                  onChange={handleDonationAmountChange}
                                             />
                                             <Select classname="currency-s"
                                                  value={selectedCurrency}
                                                  onChange={handleCurrencyChange}
                                                  options={currencyOptions}
                                                  placeholder="Search/Select currency"
                                                  isSearchable={true}
                                             />
                                        </div>
                                        <div className="d-flex jc-sb">
                                             {/* {selectedCurrency && (
                                                  <button
                                                       className="btn btn-grey btn-static btn-xs" >

                                                       <p style={{ margin: '0' }}
                                                            dangerouslySetInnerHTML={{
                                                                 __html: selectedCurrency.symbol,
                                                            }}
                                                       />
                                                  </button>
                                             )} */}
                                             <button
                                                  className={`btn btn-grey btn-xs ${activeSuggestedAmount === 10 ? 'active' : ''}`}
                                                  onClick={() => handleSuggestedAmountClick(10)}
                                             >
                                                  {selectedType === 'recurring' ? '10' : '1k'}
                                             </button>
                                             <button
                                                  className={`btn btn-grey btn-xs ${activeSuggestedAmount === 50 ? 'active' : ''}`}
                                                  onClick={() => handleSuggestedAmountClick(50)}
                                             >
                                                  {selectedType === 'recurring' ? '50' : '5k'}
                                             </button>
                                             <button
                                                  className={`btn btn-grey btn-xs ${activeSuggestedAmount === 100 ? 'active' : ''}`}
                                                  onClick={() => handleSuggestedAmountClick(100)}
                                             >
                                                  {selectedType === 'recurring' ? '100' : '10k'}
                                             </button>
                                             <button
                                                  className={`btn btn-grey btn-xs ${activeSuggestedAmount === 250 ? 'active' : ''}`}
                                                  onClick={() => handleSuggestedAmountClick(250)}
                                             >
                                                  {selectedType === 'recurring' ? '250' : '25k'}
                                             </button>


                                             <button
                                                  className={`btn btn-grey btn-xs ${activeSuggestedAmount === null ? 'active' : ''}`} // Conditionally apply "active" class
                                                  onClick={handleCustomAmountClick}
                                             >
                                                  Custom Amount
                                             </button>
                                        </div>
                                        {donationAmountError && (
                                             <p className="text-danger mb-0">{donationAmountError}</p>
                                        )}
                                   </div>
                              </div>
                              {showServiceSelection && (
                                   <div className='card common-card mb-0'>
                                        <div className="card-body">
                                             <div className="row">

                                                  <Select
                                                       isMulti
                                                       options={serviceOptions}
                                                       value={selectedServices}
                                                       onChange={handleServiceSelect}
                                                       placeholder="Select Services"
                                                       closeMenuOnSelect={false} // Keep the dropdown open after selection
                                                  />
                                             </div>
                                        </div>

                                        {serviceSelectionError && (
                                             <p className="text-danger mb-0">{serviceSelectionError}</p>
                                        )}

                                   </div>
                              )}

                              {showServiceWiseEvents && (
                                   <div className='card common-card mb-0'>
                                 <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">
                                                  <select
                                                       id="rythuEventSelect"
                                                       className="form-control"
                                                       value={selectedEvent}
                                                       onChange={handleEventSelect}
                                                  >
                                                       <option value="">Select a event</option>
                                                       {sortedEvents
                                                            .filter((event) => event.EventMasterServiceId === selectedServiceId)
                                                            .map((event) => (
                                                                 <option key={event.Id} value={event.Id}>
                                                                      {event.EventName} ({event.EventCity}) {/* Display city name */}
                                                                 </option>
                                                            ))}
                                                  </select>
                                             </div>
                                        </div>
                                   </div>
                              )}

                              {showRythuBandhamEvents && (
                                   <div className='card common-card mb-0'>
                                 <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">
                                                  <select
                                                       id="rythuEventSelect"
                                                       className="form-control"
                                                       value={selectedRythuEvent}
                                                       onChange={handleRythuEventSelect}
                                                  >
                                                       <option value="">Select a Rythubandham event</option>
                                                       {sortedEvents
                                                            .filter((event) => event.EventMasterServiceId === '1')
                                                            .map((event) => (
                                                                 <option key={event.Id} value={event.Id}>
                                                                      {event.EventName} ({event.EventCity}) {/* Display city name */}
                                                                 </option>
                                                            ))}
                                                  </select>
                                             </div>
                                        </div>
                                   </div>
                              )}

                              {showGirijanaBandhamEvents && (
                                   <div className='card common-card mb-0'>
                                 <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">
                                                  <select
                                                       id="girijanaEventSelect"
                                                       className="form-control"
                                                       value={selectedGirijanaEvent}
                                                       onChange={handleGirijanaEventSelect}
                                                  >
                                                       <option value="">Select a Girijana bandham event</option>
                                                       {sortedEvents
                                                            .filter((event) => event.EventMasterServiceId === '2')
                                                            .map((event) => (
                                                                 <option key={event.Id} value={event.Id}>
                                                                      {event.EventName} ({event.EventCity}) {/* Display city name */}
                                                                 </option>
                                                            ))}
                                                  </select>
                                             </div>
                                        </div>
                                   </div>
                              )}

                              {showGramaBandhamEvents && (
                                   <div className='card common-card mb-0'>
                                 <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">
                                                  <select
                                                       id="gramaEventSelect"
                                                       className="form-control"
                                                       value={selectedGramaEvent}
                                                       onChange={handleGramaEventSelect}
                                                  >
                                                       <option value="">Select a Grama bandham event</option>
                                                       {sortedEvents
                                                            .filter((event) => event.EventMasterServiceId === '3')
                                                            .map((event) => (
                                                                 <option key={event.Id} value={event.Id}>
                                                                      {event.EventName} ({event.EventCity}) {/* Display city name */}
                                                                 </option>
                                                            ))}
                                                  </select>
                                             </div>
                                        </div>
                                   </div>
                              )}

                              {showJanahitaBandhamEvents && (
                                   <div className='card common-card mb-0'>
                                 <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">
                                                  <select
                                                       id="janahitaEventSelect"
                                                       className="form-control"
                                                       value={selectedJanahitaEvent}
                                                       onChange={handleJanahitaEventSelect}
                                                  >
                                                       <option value="">Select a Janahita bandham event</option>
                                                       {sortedEvents
                                                            .filter((event) => event.EventMasterServiceId === '4')
                                                            .map((event) => (
                                                                 <option key={event.Id} value={event.Id}>
                                                                      {event.EventName} ({event.EventCity}) {/* Display city name */}
                                                                 </option>
                                                            ))}
                                                  </select>
                                             </div>
                                        </div>
                                   </div>
                              )}

                              {showParyavaranamBandhamEvents && (
                                   <div className='card common-card mb-0'>
                                 <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">
                                                  <select
                                                       id="paryavaranamEventSelect"
                                                       className="form-control"
                                                       value={selectedParyavaranamEvent}
                                                       onChange={handleParyavaranamEventSelect}
                                                  >
                                                       <option value="">Select a Paryavaranam bandham event</option>
                                                       {sortedEvents
                                                            .filter((event) => event.EventMasterServiceId === '5')
                                                            .map((event) => (
                                                                 <option key={event.Id} value={event.Id}>
                                                                      {event.EventName} ({event.EventCity}) {/* Display city name */}
                                                                 </option>
                                                            ))}
                                                  </select>
                                             </div>
                                        </div>
                                   </div>
                              )}


                              {showAllEvents && (
                                   <div className='card common-card mb-0'>
                                 <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">

                                                  <Select
                                                       value={selectedEvent}
                                                       onChange={handleEventSelect}
                                                       options={showAllEvents ? allEvents : sortedEvents.map((event) => ({ value: event.Id, label: event.EventName }))}
                                                       placeholder="Select an event"
                                                  />
                                             </div>
                                        </div>

                                        {/* {serviceSelectionError && (
                                             <p className="text-danger mb-0">{serviceSelectionError}</p>
                                        )} */}

                                   </div>
                              )}

                         </div>
                         {showPersonalDetails && (
                              <div className=''>
                                   <div className='card common-card'>
                                        <div className="card-header">
                                             <h4 className="head m-0">Personal Details</h4>
                                        </div>
                                        <div className="card-body" style={{ padding: '1.5vh 1.5vh 0 1.5vh' }}>
                                             <div className="row">
                                                  <div className="col pr-15 mb-15">
                                                       <div className="input-group">
                                                            <input
                                                                 type="text"
                                                                 className="mr-5 mb-0 form-control"
                                                                 value={firstName}
                                                                 onChange={(e) => {
                                                                      const inputValue = e.target.value;
                                                                      if (!/[^A-Za-z]/.test(inputValue)) {
                                                                           setFirstName(inputValue);
                                                                           setFirstNameError('');
                                                                      }
                                                                 }}
                                                                 placeholder="First Name"
                                                            />
                                                       </div>
                                                       {firstNameError && (
                                                            <p className="text-danger mb-0">{firstNameError}</p>
                                                       )}
                                                  </div>
                                                  <div className="col p-0 mb-15">
                                                       <div className="input-group">
                                                            <input
                                                                 type="text"
                                                                 className="mr-5 mb-0 form-control"
                                                                 value={lastName}
                                                                 onChange={(e) => {
                                                                      const inputValue = e.target.value;
                                                                      if (!/[^A-Za-z]/.test(inputValue)) {
                                                                           setLastName(inputValue);
                                                                           setLastNameError('');
                                                                      }
                                                                 }}
                                                                 placeholder="Last Name (optional)"
                                                            />
                                                       </div>
                                                       {lastNameError && (
                                                            <p className="text-danger mb-0">{lastNameError}</p>
                                                       )}
                                                  </div>
                                             </div>
                                             <div className="row">
                                                  <div className="col pr-15 mb-15">
                                                       <div className="input-group">
                                                            <input
                                                                 type="text"
                                                                 className="mr-5  mb-0 form-control"
                                                                 value={email}
                                                                 onChange={(e) => {
                                                                      setEmail(e.target.value);
                                                                      setEmailError(''); // Clear the error when the user interacts with the field
                                                                 }}
                                                                 placeholder="Email Id"
                                                            />
                                                       </div>
                                                       {emailError && (
                                                            <p className="text-danger mb-0">{emailError}</p>
                                                       )}
                                                  </div>
                                                  <div className="col p-0 mb-15">
                                                       <div className="input-group">
                                                            <input
                                                                 type="text"
                                                                 className="mr-5 mb-0 form-control"
                                                                 value={phoneNumber}
                                                                 onChange={(e) => {
                                                                      const inputValue = e.target.value;
                                                                      if (/^[0-9+]*$/.test(inputValue)) {
                                                                           setPhoneNumber(inputValue);
                                                                           setPhoneNumberError('');
                                                                      }
                                                                 }}
                                                                 placeholder="Phone Number"
                                                            />


                                                       </div>
                                                       {phoneNumberError && (
                                                            <p className="text-danger mb-0">{phoneNumberError}</p>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         )}

                         <div className=''>
                              <div className='card common-card'>
                                   <div className="card-body" style={{ padding: '0 1.5vh 0 1.5vh' }}>
                                        <div className="input-group mb-10 jc-sb">
                                             <div className="d-block">
                                                  <div class="d-flex" style={{ alignItems: 'baseline' }}>
                                                       <input
                                                            type="checkbox"
                                                            className="mr-5"
                                                            checked={acceptedTerms}
                                                            onChange={() => setAcceptedTerms(!acceptedTerms)}
                                                       />
                                             <label>Accept Our <Link target="_blank" to="/terms-and-condition" className="green">Terms and Conditions</Link> & <Link target="_blank"  to="/privacy-policy" className="green">
                                                            Privacy Policy
                                                       </Link></label>
                                                  </div>
                                                  {acceptedTermsError && (
                                                       <p className="text-danger mb-0">{acceptedTermsError}</p>
                                                  )}
                                             </div>
                                             <div>

                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="row jc-sb mb-15">

                                    <div className="col d-flex jc-sb">
                                        {showLogin && (
                                            <label className="cust-txt"><b>For Membership Donation <NavLink className="cust-link" to="/login">&nbsp;Login here</NavLink></b></label>
                                        )}
                                        <button className="btn btn-primary" onClick={showPersonalDetails ? handleMakePayment : handleMakePaymentWithOutPersonalDetails}>
                                            Make Payment
                                        </button>
                                    </div>
                              </div>
                         </div>

                    </div>
                    {/*{showLogin && (*/}
                    {/*     <div className="col text-center">*/}
                    {/*          <h4 className="head">For regular donation <NavLink className="cust-link" style={{ textDecoration: 'none' }} to="/login">Login here</NavLink></h4>*/}
                    {/*     </div>*/}
                    {/*)}*/}
               </div>
          </>
     );
};

export default Donation;
import React, { useState } from 'react';
import axios from 'axios';

const API_URL = 'https://manamvanam.com/api/ContactUs';
const API_URL_VOLUNTEER = 'https://manamvanam.com/api/Volunteer';
const API_URL_ENROLMENT = 'https://manamvanam.com/api/EnrollService';


const EnrollForm = ({ className, showVolunteer, showVolunteerEvent, showMessage, showSubject, showBloodGroup, eventId }) => {
     const [formData, setFormData] = useState({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          subject: '',
          bloodGroup: '',
          interestedIn: [],
          message: '',
          address: '',
     });

     const [errors, setErrors] = useState({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          subject: '',
          bloodGroup: '',
          interestedIn: '',
          message: '',
          address: '',
     });

     const [submitted, setSubmitted] = useState(false);

    const servicesData = JSON.parse(sessionStorage.getItem("manamvanam_services"));

     const validateInput = (name, value) => {
          const errorMessages = {
               firstName: 'First name is required and should only contain characters with no symbols or spaces.',
               lastName: 'Last name should only contain characters with no symbols or spaces.',
               phoneNumber: 'Phone number should only contain numbers, spaces, and "+" with a maximum of 15 digits.',
               email: 'Invalid email address.',
               subject: 'Subject is required.',
               bloodGroup: 'Blood Group is required.',
               interestedIn: 'Interested In is required.',
               message: 'Message is required.',
               address: 'Address is required.',
          };

          switch (name) {
               case 'firstName':
                    if (!value.trim()) {
                         return errorMessages.firstName;
                    }
                    if (!/^[a-zA-Z]+$/.test(value)) {
                         return errorMessages.firstName;
                    }
                    break;
               case 'lastName':
                    if (value && !/^[a-zA-Z]+$/.test(value)) {
                         return errorMessages.lastName;
                    }
                    break;
               case 'phoneNumber':
                    if (!value.trim()) {
                         return errorMessages.phoneNumber;
                    }
                    if (!/^[\d+ ]{0,15}$/.test(value)) {
                         return errorMessages.phoneNumber;
                    }
                    break;
               case 'email':
                    if (!/\S+@\S+\.\S+/.test(value)) {
                         return errorMessages.email;
                    }
                    break;
               case 'subject':
                  if (!value.trim() && (!showVolunteer && !showVolunteerEvent)) {
                         return errorMessages.subject;
                    }
                    break;
               case 'bloodGroup':
                  if (!value.trim() && (showVolunteer && showVolunteerEvent)) {
                         return errorMessages.bloodGroup;
                    }
                    break;
               case 'interestedIn':
                    if (value.length === 0) {
                         return errorMessages.interestedIn;
                    }
                    break;
               case 'message':
                  if (!value.trim() && (!showVolunteer && !showVolunteerEvent)) {
                         return errorMessages.message;
                    }
                    break;
               case 'address':
                  if (!value.trim() && showVolunteer) {
                         return errorMessages.address;
                    }
                    break;
               default:
                    break;
          }
          return '';
     };

     const handleChange = (e) => {
          const { name, value, type, checked } = e.target;
          const newValue = type === 'checkbox' ? formData.interestedIn.includes(value)
               ? formData.interestedIn.filter((item) => item !== value)
               : [...formData.interestedIn, value]
               : value;

          const errorMessage = validateInput(name, newValue);

          setErrors({ ...errors, [name]: errorMessage });
          setFormData({ ...formData, [name]: newValue });
     };

     const handleSubmit = (e) => {
          e.preventDefault();

          // Validate all form fields on submit
          const newErrors = {};
          Object.keys(formData).forEach((fieldName) => {
               const errorMessage = validateInput(fieldName, formData[fieldName]);
               newErrors[fieldName] = errorMessage;
          });

          setErrors(newErrors);

          // Check if there are any errors in the form
          const formHasErrors = Object.values(newErrors).some((error) => error !== '');

          if (!formHasErrors) {
               // Perform form submission logic here (e.g., send data to the server)
              let apiurl = API_URL;
              if (showVolunteerEvent)
                  apiurl = API_URL_ENROLMENT;
              else if (showVolunteer)
                  apiurl = API_URL_VOLUNTEER;

              axios.post(`${apiurl}`, {
                  "firstName": formData.firstName,
                  "lastName": formData.lastName,
                  "emailAddress": formData.email,
                  "mobileNo": formData.phoneNumber,
                  "subject": formData.subject,
                  "bloodGroup": formData.bloodGroup,
                  "message": formData.message,
                  "address": formData.address,
                  "eventId": eventId,
                  "interestedServices": formData.interestedIn
              }).then(response => {
                  console.log(response);
                  setSubmitted(true);
                  // Reset the form after submission
                  setFormData({
                      firstName: '',
                      lastName: '',
                      phoneNumber: '',
                      email: '',
                      subject: '',
                      bloodGroup: '',
                      interestedIn: [],
                      message: '',
                      address: '',
                  });
                  // Clear any previous errors
                  setErrors({
                      firstName: '',
                      lastName: '',
                      phoneNumber: '',
                      email: '',
                      subject: '',
                      bloodGroup: '',
                      interestedIn: '',
                      message: '',
                      address: '',
                  });
              })
                    .catch(error => {
                         // Simulating form submission delay with setTimeout (replace this with actual API call)
                         setTimeout(() => {
                              setSubmitted(true);

                              // Reset the form after submission
                              setFormData({
                                   firstName: '',
                                   lastName: '',
                                   phoneNumber: '',
                                   email: '',
                                   subject: '',
                                   bloodGroup: '',
                                   interestedIn: [],
                                   message: '',
                                   address: '',
                              });
                              // Clear any previous errors
                              setErrors({
                                   firstName: '',
                                   lastName: '',
                                   phoneNumber: '',
                                   email: '',
                                   subject: '',
                                   bloodGroup: '',
                                   interestedIn: '',
                                   message: '',
                                   address: '',
                              });
                         }, 100); // Change the delay to your desired value
                    });
          }
     };

     return (
          <div>
               <form onSubmit={handleSubmit}>
                    <div className="row">
                         <div className={className}>
                              <div className="form-group text-left">
                                   <input
                                        type="text"
                                        className={`form-control mb-0 ${errors.firstName ? 'is-invalid' : ''}`}
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        placeholder="First name"
                                   />
                                   {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                              </div>
                         </div>
                         <div className={className}>
                              <div className="form-group text-left">
                                   <input
                                        type="text"
                                        className={`form-control mb-0 ${errors.lastName ? 'is-invalid' : ''}`}
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        placeholder="Last name"
                                   />
                                   {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                              </div>
                         </div>
                    </div>
                    <div className="row">
                         <div className={className}>
                              <div className="form-group text-left">
                                   <input
                                        type="text"
                                        className={`form-control mb-0 ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        placeholder="Phone number"
                                   />
                                   {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                              </div>
                         </div>
                         <div className={className}>
                              <div className="form-group text-left">
                                   <input
                                        type="email"
                                        className={`form-control mb-0 ${errors.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                   />
                                   {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                              </div>
                         </div>
                    </div>
                    <div className="row">
                         {showSubject && (
                              <div className={className}>
                                   <div className="form-group text-left">
                                        <input
                                             type="text"
                                             className={`form-control mb-0 ${errors.subject ? 'is-invalid' : ''}`}
                                             id="subject"
                                             name="subject"
                                             value={formData.subject}
                                             onChange={handleChange}
                                             placeholder="Subject"
                                        />
                                        {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                                   </div>
                              </div>
                         )}
                         {showBloodGroup && (
                              <div className={className}>
                                   <div className="form-group text-left">
                                        <input
                                             type="text"
                                             className={`form-control mb-0 ${errors.bloodGroup ? 'is-invalid' : ''}`}
                                             id="bloodGroup"
                                             name="bloodGroup"
                                             value={formData.bloodGroup}
                                             onChange={handleChange}
                                             placeholder="Blood Group"
                                        />
                                        {errors.bloodGroup && <div className="invalid-feedback">{errors.bloodGroup}</div>}
                                   </div>
                              </div>
                         )}
                         <div className={className}><div className="form-group text-left">
                              <div
                                   className={`dropdown ${errors.interestedIn ? 'is-invalid' : ''}`}
                                   style={{ position: 'relative' }}
                              >
                                   <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="interestedInDropdown"
                                        data-bs-toggle="dropdown" // Use data-bs-toggle for Bootstrap 5
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                   >
                                        {formData.interestedIn.length === 0
                                             ? 'Select Your Interest'
                                             : formData.interestedIn.join(', ')}
                                   </button>
                                   <ul
                                        className="dropdown-menu p-3"
                                        aria-labelledby="interestedInDropdown"
                                        style={{ minWidth: '250px' }}
                                   >
                                        <li>
                                             <label className="dropdown-item">
                                                  <input
                                                       type="checkbox"
                                                       name="interestedIn"
                                                       value="All"
                                                       checked={formData.interestedIn.includes('All')}
                                                       onChange={handleChange}
                                                  />{' '}
                                                  All
                                             </label>
                                 </li>
                                 {servicesData.map((service) => (
                                     <li>
                                         <label className="dropdown-item">
                                             <input
                                                 type="checkbox"
                                                 name="interestedIn"
                                                 value={service.service_title}
                                                 checked={formData.interestedIn.includes(service.service_title)}
                                                 onChange={handleChange}
                                             />{' '}
                                             {service.service_title}
                                         </label>
                                     </li>
                                 ))}
                                        {/*<li>*/}
                                        {/*     <label className="dropdown-item">*/}
                                        {/*          <input*/}
                                        {/*               type="checkbox"*/}
                                        {/*               name="interestedIn"*/}
                                        {/*               value="RythuBandham"*/}
                                        {/*               checked={formData.interestedIn.includes('RythuBandham')}*/}
                                        {/*               onChange={handleChange}*/}
                                        {/*          />{' '}*/}
                                        {/*          Rythu Bandham*/}
                                        {/*     </label>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*     <label className="dropdown-item">*/}
                                        {/*          <input*/}
                                        {/*               type="checkbox"*/}
                                        {/*               name="interestedIn"*/}
                                        {/*               value="GirijanaBandham"*/}
                                        {/*               checked={formData.interestedIn.includes('GirijanaBandham')}*/}
                                        {/*               onChange={handleChange}*/}
                                        {/*          />{' '}*/}
                                        {/*          Girijana Bandham*/}
                                        {/*     </label>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*     <label className="dropdown-item">*/}
                                        {/*          <input*/}
                                        {/*               type="checkbox"*/}
                                        {/*               name="interestedIn"*/}
                                        {/*               value="GramaBandham"*/}
                                        {/*               checked={formData.interestedIn.includes('GramaBandham')}*/}
                                        {/*               onChange={handleChange}*/}
                                        {/*          />{' '}*/}
                                        {/*          Grama Bandham*/}
                                        {/*     </label>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*     <label className="dropdown-item">*/}
                                        {/*          <input*/}
                                        {/*               type="checkbox"*/}
                                        {/*               name="interestedIn"*/}
                                        {/*               value="JanahitaBandham"*/}
                                        {/*               checked={formData.interestedIn.includes('JanahitaBandham')}*/}
                                        {/*               onChange={handleChange}*/}
                                        {/*          />{' '}*/}
                                        {/*          Janahita Bandham*/}
                                        {/*     </label>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*     <label className="dropdown-item">*/}
                                        {/*          <input*/}
                                        {/*               type="checkbox"*/}
                                        {/*               name="interestedIn"*/}
                                        {/*               value="ParyavaranamBandham"*/}
                                        {/*               checked={formData.interestedIn.includes('ParyavaranamBandham')}*/}
                                        {/*               onChange={handleChange}*/}
                                        {/*          />{' '}*/}
                                        {/*          Paryavaranam Bandham*/}
                                        {/*     </label>*/}
                                        {/*</li>*/}
                                   </ul>
                              </div>
                              {errors.interestedIn && <div className="invalid-feedback">{errors.interestedIn}</div>}
                         </div></div>
                    </div>
                    {showMessage && (
                         <div className="row">
                              <div className="col-12">
                                    <div className="form-group text-left new-tf">
                                        <textarea
                                             className={`form-control mb-0 ${errors.message ? 'is-invalid' : ''}`}
                                             id="message"
                                             name="message"
                                             value={formData.message}
                                             onChange={handleChange}
                                             placeholder="Message"
                                        />
                                        {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                                   </div>
                              </div>
                         </div>
                    )}
                    {showVolunteer && (
                         <>
                         <div className="row">
                              <div className="col-12">
                                   <div className="form-group text-left">
                                        <textarea
                                             className={`form-control mb-0 ${errors.address ? 'is-invalid' : ''}`}
                                             id="address"
                                             name="address"
                                             value={formData.address}
                                             onChange={handleChange}
                                             placeholder="Address Line 1"
                                        />
                                        {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                                   </div>
                              </div>
                         </div>
                          <div className="row">
                              <div className="col-12">
                                   <div className="form-group text-left">
                                        <textarea
                                             className="form-control mb-0"
                                             id="address"
                                             name="address"
                                             placeholder="Address Line 2"
                                        />
                                        {/* {errors.address && <div className="invalid-feedback">{errors.address}</div>} */}
                                   </div>
                              </div>
                         </div>
                              <div className="row">
                                   <div className="col-6">
                                        <div className="form-group text-left">
                                             <input
                                                  type="text"
                                                  className="form-control mb-0"
                                                  id="city"
                                                  name="city"
                                                  onChange={handleChange}
                                                  placeholder="City"
                                             />
                                             {/* {errors.address && <div className="invalid-feedback">{errors.address}</div>} */}
                                        </div>
                                   </div>
                                   <div className="col-6">
                                        <div className="form-group text-left">
                                             <input
                                                  type="text"
                                                  className="form-control mb-0"
                                                  id="state"
                                                  name="state"
                                                  onChange={handleChange}
                                                  placeholder="State"
                                             />
                                             {/* {errors.address && <div className="invalid-feedback">{errors.address}</div>} */}
                                        </div>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col-6">
                                        <div className="form-group text-left">
                                             <input
                                                  type="text"
                                                  className="form-control mb-0"
                                                  id="country"
                                                  name="country"
                                                  onChange={handleChange}
                                                  placeholder="Country"
                                             />
                                             {/* {errors.address && <div className="invalid-feedback">{errors.address}</div>} */}
                                        </div>
                                   </div>
                                   <div className="col-6">
                                        <div className="form-group text-left">
                                             <input
                                                  type="text"
                                                  className="form-control mb-0"
                                                  id="zipcode"
                                                  name="zipcode"
                                                  onChange={handleChange}
                                                  placeholder="Zipcode"
                                             />
                                             {/* {errors.address && <div className="invalid-feedback">{errors.address}</div>} */}
                                        </div>
                                   </div>
                              </div>
                         </>
                    )}
                    {showVolunteerEvent && (
                         <div className='row'>
                              <div className='col-12'>
                                   <div className='form-group text-left'>
                                        <div class="d-flex" style={{ alignItems: 'baseline' }}>
                                             <input
                                                  type="checkbox"
                                                  className="mr-5"
                                             />
                                             <label>Become Volunteer for the event</label>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    )}
                    <div className="row">
                         <div className="col text-right">
                              <button type="submit" className="btn btn-primary">
                                   Submit
                              </button>
                         </div>
                    </div>













               </form>

               {/* Popup to show after form submission */}
               {submitted && (
                    <div className="modal d-block">
                         <div className="modal-dialog">
                              <div className="modal-content">
                                   <div className="modal-header">
                                        <h5 className="modal-title">Form Submitted!</h5>
                                        <button
                                             type="button"
                                             className="close"
                                             onClick={() => setSubmitted(false)}
                                        >
                                             &times;
                                        </button>
                                   </div>
                                   <div className="modal-body">
                                        <p>Thank you for registering with us. Our team will get back to you soon.</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               )}
          </div>
     );
};

export default EnrollForm;
